/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AdminService } from './services/AdminService';
import { LoanService } from './services/LoanService';
import { LoanPublicService } from './services/LoanPublicService';
import { PaymentService } from './services/PaymentService';
import { ShopService } from './services/ShopService';
import { UserService } from './services/UserService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class MotalClient {
    public readonly admin: AdminService;
    public readonly loan: LoanService;
    public readonly loanPublic: LoanPublicService;
    public readonly payment: PaymentService;
    public readonly shop: ShopService;
    public readonly user: UserService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? 'http://motal-api.ne7shii.me',
            VERSION: config?.VERSION ?? '0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.admin = new AdminService(this.request);
        this.loan = new LoanService(this.request);
        this.loanPublic = new LoanPublicService(this.request);
        this.payment = new PaymentService(this.request);
        this.shop = new ShopService(this.request);
        this.user = new UserService(this.request);
    }
}

