/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ShopInviteRequest = {
    inviteEmailAddress?: string;
    role?: ShopInviteRequest.role;
};
export namespace ShopInviteRequest {
    export enum role {
        OWNER = 'OWNER',
        STAFF = 'STAFF',
    }
}

