import CircleIcon from '@mui/icons-material/Circle';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Box, Button, Card, CardContent, Divider, FormControl, InputLabel, List, ListItem, ListItemButton, ListItemText, MenuItem, Pagination, Select, Stack, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useMotalClient } from "../client/motal-api";
import { AdminShopUpdateRequest, Shop } from "../client/motal-api/generated";


export const ShopScreen = () => {
    const [page, setPage] = useState<number>(1)
    const motalClient = useMotalClient()
    const [searchText, setSearchText] = useState<string>('')
    const [selectedShop, setSelectedShop] = useState<Shop | null>(null)
    const shopsQuery = useQuery({ queryKey: ['shops', searchText, page], queryFn: () => motalClient.admin.getShops1({ search: searchText, page: page }) })

    const shopUpdateMutation = useMutation({ mutationKey: [selectedShop?.id], mutationFn: (req: { shopId: string, requestBody: AdminShopUpdateRequest }) => motalClient.admin.updateShop1({ shopId: req.shopId || '', requestBody: req.requestBody }) })
    const form = useForm<AdminShopUpdateRequest>({})
    const handleSubmit = async () => {
        if (selectedShop?.id) {
            form.handleSubmit(async (data) => {
                shopUpdateMutation.mutateAsync({
                    shopId: selectedShop.id || '', requestBody: {
                        status: data.status ? data.status : undefined,
                        expireAt: data.expireAt ? dayjs(data.expireAt, 'YYYY-MM-DD').toISOString() : dayjs(selectedShop.expireAt).toISOString()

                    }
                }, {
                    onSuccess: () => {
                        toast.success('บันทึกสำเร็จ')
                        setSelectedShop(null)
                        shopsQuery.refetch()

                    }
                })
            })()
        }
    }
    useEffect(() => {
        if (selectedShop?.id) {
            form.reset({ status: selectedShop.status, expireAt: '' })
        }
    }, [form, selectedShop?.id, selectedShop?.status])
    const handleAddDate = (dayCount: number) => {
        if (selectedShop?.expireAt) { //no existing expireAt
            const expireAt = dayjs(selectedShop?.expireAt).add(dayCount, 'day').format('YYYY-MM-DD')
            form.setValue('expireAt', expireAt, { shouldDirty: true })
        }
        else {
            const expireAt = dayjs().add(dayCount, 'day').format('YYYY-MM-DD')
            form.setValue('expireAt', expireAt, { shouldDirty: true })
        }
    }
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                <Box sx={{ backgroundColor: 'white', zIndex: 2, p: 2, width: '100%', boxShadow: 1 }}>
                    <TextField
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                        variant="outlined"
                        placeholder="ค้าหา"
                        fullWidth
                    />
                </Box>
                <Stack direction='row' mt={1}>
                    <Box width={280} sx={{ p: 2, backgroundColor: 'white', boxShadow: 1, width: 280, minHeight: '90vh' }}>
                        {shopsQuery.isFetching ? <Typography>loading...</Typography> : <Stack direction='column' >
                            <List>
                                <ListItem>
                                    <Typography>Total: {shopsQuery.data?.pagination?.totalElements?.toLocaleString()}</Typography>
                                </ListItem>
                                <Divider />
                                <Pagination sx={{ my: '10px' }} page={page} count={shopsQuery.data?.pagination?.totalPages || 1} onChange={(e, n) => { setPage(n) }} />
                                <Divider />


                                {
                                    shopsQuery.data && shopsQuery.data.content?.map((shop, index) => (
                                        <>
                                            <ListItem key={shop.name} disablePadding>
                                                <ListItemButton onClick={() => setSelectedShop(shop)}>
                                                    <Stack width={'100%'}>
                                                        <Stack sx={{ width: '100%' }} display={'flex'} direction={"row"} justifyContent={'space-between'} alignItems={'center'} >
                                                            <ListItemText primary={shop.name} />
                                                            <CircleIcon sx={{ color: shop.status === Shop.status.ACTIVE ? 'green' : 'orange' }} />
                                                        </Stack>
                                                        <ListItemText primary={shop.phoneNumber} secondary={shop.address} />
                                                    </Stack>
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    ))
                                }
                            </List>
                        </Stack>}
                    </Box>
                    <Box sx={{ mx: 1, p: 2, backgroundColor: 'white', boxShadow: 1, width: { sm: `calc(100% - 280px)` } }}>
                        {selectedShop && <Card>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {selectedShop.name}
                                </Typography>
                                {/* <Typography color="text.secondary">
                                Status: {selectedShop.status}
                            </Typography> */}
                                <FormControl sx={{ marginTop: '20px', marginBottom: '20px', width: '300px' }}>
                                    <InputLabel id="shop-status-label">Status</InputLabel>

                                    <Controller
                                        name="status"
                                        control={form.control}
                                        render={({ field }) => (
                                            <Select

                                                labelId="shop-status-label"
                                                id="shop-status"
                                                label="Status"
                                                variant={'outlined'}
                                                value={field.value || ''}
                                                onChange={(e) => field.onChange(e.target.value)}
                                            >
                                                <MenuItem value={Shop.status.ACTIVE}>Active</MenuItem>
                                                <MenuItem value={Shop.status.WAITING_FOR_APPROVE}>Wait for Approve</MenuItem>
                                                <MenuItem value={Shop.status.SUSPENDED}>Suspended</MenuItem>
                                                <MenuItem value={Shop.status.EXPIRED}>Expired</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <Typography variant="body2" component="div">
                                    Address: {selectedShop.address}
                                </Typography>
                                <Typography variant="body2" component="div">
                                    Phone Number: {selectedShop.phoneNumber}
                                </Typography>
                                <Typography variant="body2" component="div">
                                    Facebook: {selectedShop.facebook}
                                </Typography>

                                {/* <Divider sx={{ my: 2 }} /> */}

                                {/* <Typography variant="h6" component="div">
                                Payment Channels
                            </Typography> */}
                                {/* <List>
                                {selectedShop.paymentChannels?.map((channel, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`${channel.type} (${channel.status})`}
                                            secondary={`Account ID: ${channel.accountId || 'N/A'}${channel.bankCode ? `, Bank Code: ${channel.bankCode}` : ''
                                                }`}
                                        />
                                    </ListItem>
                                ))}
                            </List> */}
                                {/* 
                            <Divider sx={{ my: 2 }} />

                            <Typography variant="h6" component="div">
                                Members
                            </Typography>
                            <List>
                                {selectedShop.members?.map((member, index) => (
                                    <ListItem key={index}>
                                        <ListItemText
                                            primary={`User ID: ${member.userId}`}
                                            secondary={`Role: ${member.role}`}
                                        />
                                    </ListItem>
                                ))}
                            </List> */}

                                <Divider sx={{ my: 2 }} />

                                <Typography variant="body2" color="text.secondary">
                                    Created At: {selectedShop.createdAt ? new Date(selectedShop.createdAt).toLocaleString('th') : ''}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Updated At: {selectedShop.updatedAt ? new Date(selectedShop.updatedAt).toLocaleString('th') : ''}
                                </Typography>
                                <Stack direction='row' gap={2} alignItems={'center'}>
                                    <Typography variant="body2" color="text.secondary">
                                        Expires At: {selectedShop.expireAt ? (dayjs(selectedShop.expireAt)).format('DD/MM/YYYY') : ''}
                                    </Typography>
                                    <DoubleArrowIcon />
                                    <Controller control={form.control}
                                        name="expireAt"
                                        render={({ field }) => (
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                value={field.value ? dayjs(field.value) : null}  // Convert to Dayjs
                                                onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : '')}
                                                slotProps={{ textField: { size: 'small', variant: 'outlined' } }} />
                                        )} />
                                    {form.watch('expireAt') && selectedShop.expireAt && <Typography>Date diff: {
                                        dayjs(form.watch('expireAt')).diff(dayjs(selectedShop.expireAt || dayjs()), 'day')
                                    }</Typography>}

                                </Stack>
                                <Stack mt={'20px'} direction={'row'} gap={3}>
                                    <Button
                                        onClick={() => handleAddDate(30)}
                                        variant="contained"
                                    >
                                        Add 30 days
                                    </Button>
                                    <Button
                                        onClick={() => handleAddDate(60)}
                                        variant="contained"
                                    >
                                        Add 60 days
                                    </Button>
                                    <Button
                                        onClick={() => handleAddDate(90)}
                                        variant="contained"
                                    >
                                        Add 90 days
                                    </Button>
                                    <Button
                                        onClick={() => handleAddDate(180)}
                                        variant="contained"
                                    >
                                        Add 180 days
                                    </Button>
                                    <Button
                                        onClick={() => handleAddDate(365)}
                                        variant="contained"
                                    >
                                        Add 1 year
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>}
                    </Box>
                </Stack>
            </LocalizationProvider>
            <Box sx={{ visibility: (form.formState.isDirty && selectedShop) ? 'visible' : 'hidden', p: 2, bgcolor: 'white', width: { xs: '100%', sm: 'calc(100% - 240px)' }, position: 'fixed', bottom: 0, zIndex: 99 }} >
                <Stack direction='row' gap={1} justifyContent='center'>
                    <Button variant='outlined' size='large'
                        onClick={() => form.reset({ status: selectedShop?.status, expireAt: '' })}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>ยกเลิก</Button>
                    <Button variant='contained' size='large'
                        onClick={handleSubmit}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>บันทึก</Button>
                </Stack>
            </Box >
        </>
    )
} 