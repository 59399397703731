import { Box, Card, CardContent, Divider, List, ListItem, ListItemButton, ListItemText, Pagination, Stack, TextField, Typography } from "@mui/material";
import { useMotalClient } from "../client/motal-api";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { User } from "../client/motal-api/generated";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


export const UserScreen = () => {
    const [searchText, setSearchText] = useState<string>('')
    const [selectedUser, setSelectedUser] = useState<User>()
    const [page, setPage] = useState<number>(1)
    const motalClient = useMotalClient()
    const userQuery = useQuery({ queryKey: ['users', page, searchText], queryFn: () => motalClient.admin.getUsers({ search: searchText.length >= 3 ? searchText : undefined, page: page }), refetchOnWindowFocus: false })


    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"th"}>
                <Box sx={{ backgroundColor: 'white', zIndex: 2, p: 2, width: '100%', boxShadow: 1 }}>
                    <TextField
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        sx={{ backgroundColor: 'white' }}
                        variant="outlined"
                        placeholder="ค้าหา"
                        fullWidth
                    />
                </Box>
                <Stack direction='row' mt={1}>
                    <Box width={280} sx={{ p: 2, backgroundColor: 'white', boxShadow: 1, width: 280, minHeight: '90vh' }}>
                        {userQuery.isFetching ? <Typography>loading...</Typography> : <Stack direction='column' >
                            <Divider />
                            <List>
                                <ListItem>
                                    <Typography>Total: {userQuery.data?.pagination?.totalElements?.toLocaleString()}</Typography>
                                </ListItem>
                                <Divider />
                                <Pagination sx={{ my: '10px' }}  page={page} count={userQuery.data?.pagination?.totalPages || 1} onChange={(e, n) => { setPage(n) }} />

                                <Divider />
                                {
                                    userQuery.data && userQuery.data.content?.map((user, index) => (
                                        <>
                                            <ListItem key={user.id} disablePadding>
                                                <ListItemButton onClick={() => setSelectedUser(user)}>
                                                    <Stack width={'100%'}>
                                                        <Stack sx={{ width: '100%' }} display={'flex'} direction={"row"} justifyContent={'space-between'} alignItems={'center'} >
                                                            <ListItemText primary={`${user.firstName} ${user.laseName}`} />
                                                            {/* <CircleIcon sx={{ color: user.status === Shop.status.ACTIVE ? 'green' : 'orange' }} /> */}
                                                        </Stack>
                                                        <ListItemText primary={user.phoneNumber} secondary={user.primaryEmail} />
                                                    </Stack>
                                                </ListItemButton>
                                            </ListItem>
                                            <Divider />
                                        </>
                                    ))
                                }
                            </List>
                        </Stack>}
                    </Box>
                    <Box sx={{ mx: 1, p: 2, backgroundColor: 'white', boxShadow: 1, width: { sm: `calc(100% - 280px)` } }}>
                        {selectedUser && <Box sx={{ p: 2 }}>
                            <Card variant="outlined" sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">{selectedUser.primaryEmail}</Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        First Name: {selectedUser.firstName}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Last Name: {selectedUser.laseName}
                                    </Typography>

                                    <Typography variant="body2" color="text.secondary">
                                        Phone Number: {selectedUser.phoneNumber || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        National ID: {selectedUser.nationalId || '-'}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        System Role: {selectedUser.systemRole}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>}
                    </Box>
                </Stack>
            </LocalizationProvider>
            {/* <Box sx={{ visibility: (form.formState.isDirty && selectedShop) ? 'visible' : 'hidden', p: 2, bgcolor: 'white', width: { xs: '100%', sm: 'calc(100% - 240px)' }, position: 'fixed', bottom: 0, zIndex: 99 }} >
                <Stack direction='row' gap={1} justifyContent='center'>
                    <Button variant='outlined' size='large'
                        onClick={() => form.reset({ status: selectedShop?.status, expireAt: '' })}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>ยกเลิก</Button>
                    <Button variant='contained' size='large'
                        onClick={handleSubmit}
                        sx={{
                            width: {
                                xs: '100%', sm: '100%', md: 200
                            },
                        }}>บันทึก</Button>
                </Stack>
            </Box > */}
        </>
    )
} 