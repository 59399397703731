/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentChannel } from './PaymentChannel';
export type PaymentEvent = {
    id?: string;
    shopId: string;
    customerId?: string;
    loanId: string;
    note?: string;
    repaymentTermNumber?: number;
    transactionScheduledAt?: string;
    transactionAt?: string;
    amount: number;
    paymentChannel?: PaymentChannel;
    paymentChannelRefId?: string;
    status?: PaymentEvent.status;
    type?: PaymentEvent.type;
    createdAt?: string;
    updatedBy?: string;
    updatedAt?: string;
};
export namespace PaymentEvent {
    export enum status {
        PENDING = 'PENDING',
        COMPLETED = 'COMPLETED',
        CANCEL = 'CANCEL',
        OVERDUE = 'OVERDUE',
    }
    export enum type {
        DOWN_PAYMENT = 'DOWN_PAYMENT',
        REPAYMENT_PARTIAL = 'REPAYMENT_PARTIAL',
        REPAYMENT_FULL = 'REPAYMENT_FULL',
        FINE = 'FINE',
        CLOSED = 'CLOSED',
    }
}

