import { useAuth, useClerk, UserButton } from '@clerk/clerk-react';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Breadcrumbs, CssBaseline, Drawer, IconButton, Link, Stack, Toolbar, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useMotalClient } from '../../client/motal-api';
import { AppDrawer } from './AppDrawer';


const drawerWidth = 260;

interface Props {
    window?: () => Window;
}


export const AppLayout = (props: Props) => {
    const { userId, isLoaded } = useAuth()
    const { signOut } = useClerk();
    const navigate = useNavigate()

    React.useEffect(() => {
        if (isLoaded && !userId) {
            navigate("/sign-in")
        }
    }, [isLoaded, navigate, userId])
    const motalClient = useMotalClient()
    const checkSystemAdminQuery = useQuery({ queryKey: ['checkAdmin', userId], queryFn: () => motalClient.admin.getAdminProfile(), enabled: isLoaded, staleTime: 1000 * 60 * 60 * 24, refetchOnWindowFocus: false, retry: false })
    useEffect(() => {
        if (checkSystemAdminQuery.isError) {
            signOut({ redirectUrl: '/unauthorize' })
        }
    }, [checkSystemAdminQuery.isError, navigate, signOut])


    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);

    // const { shopId } = useParams()
    // const routeExactMatch = useMatch("/:shopId")



    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const container = window !== undefined ? () => window().document.body : undefined;
    if (!isLoaded || checkSystemAdminQuery.status === 'pending') return <div>"Loading..."</div>

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    backgroundImage: 'linear-gradient(to right, #263238, #37474f)'
                }}
            >
                <Toolbar>
                    <IconButton
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Stack direction='row' width='100%' justifyContent='space-between'>
                        <Box sx={{ flexGrow: 1 }}>
                            <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'white' }}>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href="/"
                                >
                                    {/* <HomeIcon sx={{ mr: 0.5, textShadow: '2px 2px 2px #263238', color: 'white' }} fontSize="small" /> */}
                                    <Typography variant='h6' fontWeight='bold' color='white' sx={{ textShadow: '2px 2px 2px #263238' }} >MotalVip</Typography>
                                </Link>
                                <Link
                                    underline="hover"
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    color="inherit"
                                    href={``}
                                >
                                    <Typography sx={{ mr: 0.5, textShadow: '2px 2px 2px #263238', color: 'white' }}> Admin</Typography>
                                </Link>
                            </Breadcrumbs>
                        </Box>
                        <UserButton />
                    </Stack>
                </Toolbar>
            </AppBar>

            <>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="mailbox folders"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                        container={container}
                        variant='temporary'
                        open={mobileOpen}
                        onTransitionEnd={handleDrawerTransitionEnd}
                        onClose={handleDrawerClose}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                    >
                        <AppDrawer />
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                        }}
                        open
                    >
                        <AppDrawer />
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, backgroundColor: '#fafafa', minHeight: '100vh' }}
                >
                    <Toolbar />
                    <Outlet />
                </Box>
            </>
        </Box>
    )
}
