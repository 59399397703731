import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
// import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from '@mui/material';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

const menuData = [

    {
        key: '/loans',
        label: 'สินเชื่อ',
        icon: <CreditScoreOutlinedIcon />
    },


    {
        key: '/shops',
        label: 'ร้านค้า',
        icon: <StoreOutlinedIcon />
    },
    {
        key: '/users',
        label: 'ผู้ใช้งาน',
        icon: <PeopleOutlinedIcon />
    },
]

type ShopDrawerProps = {
    // shopId: string

}
export const AppDrawer = (props: ShopDrawerProps) => {
    const location = useLocation()
    return (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {
                    menuData.map((menu, index) => (
                        <ListItem key={menu.label} disablePadding>
                            {menu.key === '/:shopId/coming-soon'
                                ?
                                <ListItemButton disabled>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.label} />
                                </ListItemButton>
                                :
                                <ListItemButton
                                    href={menu.key}
                                    selected={matchPath(location.pathname, menu.key) ? true : false}>
                                    <ListItemIcon>
                                        {menu.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={menu.label} />
                                </ListItemButton>
                            }
                        </ListItem>
                    ))
                }
            </List>
        </div >
    )
}