/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BusinessExceptionResponse = {
    code?: BusinessExceptionResponse.code;
    message?: string;
};
export namespace BusinessExceptionResponse {
    export enum code {
        NOT_FOUND = 'NOT_FOUND',
        ACCESS_DENIED = 'ACCESS_DENIED',
        UNHANDLED = 'UNHANDLED',
    }
}

