/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaymentChannel } from './PaymentChannel';
export type ShopEvent = {
    id?: string;
    name: string;
    address: string;
    phoneNumber: string;
    facebook: string;
    lineId?: string;
    shopPrimaryImageFile?: string;
    shopPrimaryImageUrl?: string;
    paymentChannels?: Array<PaymentChannel>;
    status?: ShopEvent.status;
    expireAt?: string;
};
export namespace ShopEvent {
    export enum status {
        ACTIVE = 'ACTIVE',
        WAITING_FOR_APPROVE = 'WAITING_FOR_APPROVE',
        EXPIRED = 'EXPIRED',
        SUSPENDED = 'SUSPENDED',
    }
}

