import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './App.css';
import { AppLayout } from './components/layout/AppLayout';
import SignInPage from './screen/SignIn';
import { ShopScreen } from './screen/ShopScreen';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import UnauthorizePage from "./screen/Unauthorize";
import { LoanScreen } from "./screen/LoanScreen";
import { UserScreen } from "./screen/UserScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/shops",
        element: <ShopScreen />
      },
      {
        path: "/loans",
        element: <LoanScreen />
      },
      {
        path: "/users",
        element: <UserScreen />
      }
    ]
  },
  { path: "/sign-in/*", element: <SignInPage /> },
  { path: "/unauthorize/*", element: <UnauthorizePage /> },


]);

// Create a client
const queryClient = new QueryClient()
function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
