import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

interface DialogContextProps {
    showDialog: (options: DialogOptions) => void;
}

interface DialogOptions {
    title: string;
    description: string;
    onConfirm: () => void;
}

interface DialogProviderProps {
    children: ReactNode;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export const useDialog = (): DialogContextProps => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error('useDialog must be used within a DialogProvider');
    }
    return context;
};

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
    const [dialog, setDialog] = useState<DialogOptions & { open: boolean }>({
        open: false,
        title: '',
        description: '',
        onConfirm: () => { },
    });

    const showDialog = useCallback(({ title, description, onConfirm }: DialogOptions) => {
        setDialog({
            open: true,
            title,
            description,
            onConfirm,
        });
    }, []);

    const closeDialog = useCallback(() => {
        setDialog((prevState) => ({
            ...prevState,
            open: false,
        }));
    }, []);

    const handleConfirm = useCallback(() => {
        if (dialog.onConfirm) {
            dialog.onConfirm();
        }
        closeDialog();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialog.onConfirm, closeDialog]);

    return (
        <DialogContext.Provider value={{ showDialog }}>
            {children}
            <Dialog
                open={dialog.open}
                onClose={closeDialog}
                sx={{ minWidth: '400px' }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialog.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog}>Disagree</Button>
                    <Button onClick={handleConfirm} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </DialogContext.Provider>
    );
};
