/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalculateRepaymentScheduleRequest } from '../models/CalculateRepaymentScheduleRequest';
import type { PaginatedResponsePaymentEvent } from '../models/PaginatedResponsePaymentEvent';
import type { PaymentEvent } from '../models/PaymentEvent';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PaginatedResponsePaymentEvent OK
     * @throws ApiError
     */
    public searchPayment({
        shopId,
        loanId,
        status,
        page = 1,
        limit = 10,
    }: {
        shopId: string,
        loanId?: string,
        status?: 'PENDING' | 'COMPLETED' | 'CANCEL' | 'OVERDUE',
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedResponsePaymentEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payments',
            query: {
                'shopId': shopId,
                'loanId': loanId,
                'status': status,
                'page': page,
                'limit': limit,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaymentEvent OK
     * @throws ApiError
     */
    public createPayment({
        requestBody,
    }: {
        requestBody: PaymentEvent,
    }): CancelablePromise<PaymentEvent> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payments',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaymentEvent OK
     * @throws ApiError
     */
    public previewRepayment({
        requestBody,
    }: {
        requestBody: CalculateRepaymentScheduleRequest,
    }): CancelablePromise<Array<PaymentEvent>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/payments/preview-repayment',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaymentEvent OK
     * @throws ApiError
     */
    public getPaymentById({
        paymentId,
    }: {
        paymentId: string,
    }): CancelablePromise<PaymentEvent> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/payments/{paymentId}',
            path: {
                'paymentId': paymentId,
            },
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * @returns PaymentEvent OK
     * @throws ApiError
     */
    public updatePayment({
        paymentId,
        requestBody,
    }: {
        paymentId: string,
        requestBody: PaymentEvent,
    }): CancelablePromise<PaymentEvent> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/payments/{paymentId}',
            path: {
                'paymentId': paymentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
}
